import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '@/views/Homepage.vue'
import login from '../views/Login.vue'
import forgotPassword from '@/views/forgotPassword.vue'
import forgotPasswordSuccess from '@/views/forgotPasswordSuccess.vue'


Vue.use(VueRouter)

const routes = [
    // {

    //     path: '/',
    //     name: 'login',
    //     // component: login
    //     component: () => import ('@/views/LanddingPage.vue')
    // },
    {
        path: '/forgotpassword',
        name: 'forgot',
        component: forgotPassword
    },
    {
        path: '/forgotPasswordSuccess',
        name: 'forgotSuccess',
        component: forgotPasswordSuccess
    },
    {
        path: '/printpdf',
        name: 'printpdf',
        component: () => import ('@/views/printpdf.vue')
    }, {
        path: '/ExportVat',
        name: 'ExportVat',
        component: () => import ('@/views/ExportExcel/ExportVat.vue')
    }, {
        path: '/invc',
        name: 'invc',
        component: () => import ('@/views/ExportExcel/ExportInvoiceeee.vue')
    }, {
        path: '/',
        component: Homepage,
        children: [


            {
                path: '/',
                name: 'LanddingPage',
                component: () => import ('@/views/LanddingPage.vue')
            },
            // {
            //     path: '/Report2',
            //     name: 'Report2',
            //     component: () =>
            //         import('@/components/Report/Report2.vue')
            // },
        ]
    }
]

const router = new VueRouter({mode: 'history', base: process.env.BASE_URL, routes})

export default router
