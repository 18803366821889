<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app width="150px">
      <v-row class="mt-12"></v-row>
        <v-list height="80" class="mt-0 mb-2 pa-0" style="text-align: center">
          <a href="/">
            <img
              src="@/assets/chum_logo.jpg"
              width="80px"
              height="80px"
              class="ma-0 pa-0 mt-12"
            />
          </a>
          <!-- ext-align-last: center; -->
          <!-- <img
            src="@/assets/icon/menu.png"
            width="auto"
            height="25"
            style="float: right"
            @click.stop="drawer = !drawer"
          /> -->
        </v-list>
        <!-- <v-divider></v-divider> -->
        <v-row class="mt-12"></v-row>
        <v-row class="mt-12"></v-row>
        <v-row class="mt-12"></v-row>
        <v-row class="mt-12"></v-row>
        <v-row align="center" justify="center" class="mt-12">
          <v-list-item style="place-content: center; margin-top: 20px">
            <v-btn
              :text="selected == 1 ? true : false"
              :elevation="selected == 1 ? 3 : 0"
              style="border-radius: 20px;background-color: white; text-align: -webkit-center"
              width="70px"
              height="70px"
              @click="selected = 1"
            >
              <v-img src="@/assets/menu01.png" max-width="30px" />
            </v-btn>
          </v-list-item>
          <v-list-item style="place-content: center; margin-top: 20px">
            <v-btn
              :text="selected == 2 ? true : false"
              :elevation="selected == 2 ? 3 : 0"
              style="border-radius: 20px;background-color: white; text-align: -webkit-center"
              width="70px"
              height="70px"
              @click="selected = 2"
            >
              <v-img src="@/assets/menu02.png" max-width="30px" />
            </v-btn>
          </v-list-item>
          <v-list-item style="place-content: center; margin-top: 20px">
            <v-btn
              :text="selected == 3 ? true : false"
              :elevation="selected == 3 ? 3 : 0"
              style="border-radius: 20px;background-color: white; text-align: -webkit-center"
              width="70px"
              height="70px"
              @click="selected = 3"
            >
              <v-img src="@/assets/menu03.png" max-width="30px" />
            </v-btn>
          </v-list-item>

          <v-col cols="12">
            <!-- <v-divider class="mt-4"></v-divider> -->
          </v-col>
        </v-row>
      </v-navigation-drawer>
      <!-- <v-app-bar app elevation="4" color="white">
        <img
          v-if="!drawer"
          class="mr-2"
          src="@/assets/icon/menu.png"
          width="auto"
          height="25"
          style="float: right"
          @click.stop="drawer = !drawer"
        />
        <v-spacer></v-spacer>
        <div class="text-right mt-7 ml-4">
          <v-speed-dial
            v-model="fab"
            bottom
            right
            direction="bottom"
            :open-on-hover="false"
            :transition="transition"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" text center small>
                <v-icon v-if="fab"> mdi-close </v-icon>
                <v-icon v-else> mdi-account </v-icon>
              </v-btn>
            </template>

            <v-list min-width="110px" outlined>
              <v-list-item @click="goTo('profile')">Profile</v-list-item>
              <v-list-item @click="Logout()">Logout</v-list-item>
            </v-list>
          </v-speed-dial>
        </div>
      </v-app-bar> -->
      <v-main class="div_overflow">
        <!-- <v-container grid-list-xs> -->
        <div class="pa-4">
          <router-view></router-view>
        </div>
        <!-- </v-container> -->
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      selected: 1,
      drawer: true,

      Header: "",
      // picture: "",
      username: "",
      NavigatorAdmin: [
        {
          headerName: "จัดการข้อมูล",
          menuItem: [
            {
              key: "menu3",
              text: "สาระความรู้",
              path: "/ManageBanners_3",
              name: "ManageBanners_3",
              icon: "youtube",
            },
          ],
        },
      ],
      NavigatorDefaut: [
        {
          headerName: "ข้อมูลรีพอร์ท",
          menuItem: [
            {
              key: "table1",
              text: "ข้อมูลการแจ้งเบาะแส",
              path: "/table1",
              name: "table1",
              icon: "youtube",
              pic: "@/assets/menu01.png",
            },
            {
              key: "table2",
              text: "รายชื่อผู้ต้องสงสัย",
              path: "/table2",
              name: "table2",
              icon: "youtube",
              pic: "@/assets/menu02.png",
            },
            {
              key: "table3",
              text: "ข้อมูลตามประเภทข้อมูล",
              path: "/table3",
              name: "table3",
              icon: "youtube",
              pic: "@/assets/menu03.png",
            },
          ],
        },
      ],

      Navigator: [],
      fab: false,
      transition: "slide-y-reverse-transition",
    };
  },
  created() {
    this.Navigator = this.NavigatorDefaut;
    var checkuser = JSON.parse(
      Decode.decode(localStorage.getItem("userBebraveWeb"))
    );
    console.log("user", checkuser);
    this.username = checkuser.name;

    // if (checkuser.user.user_type == "99") {
    // this.Navigator = this.NavigatorAdmin;
    // } else {
    //   this.Navigator = this.NavigatorDefaut;
    // }

    this.CheckHeader();
  },
  methods: {
    howto() {
      window.open(
        "https://drive.google.com/file/d/1wVZ6qIWo_W3c9No1O7Ujg0peD_0Tbvxe/view?usp=sharing",
        "_blank"
      );
    },
    openKeys(val) {
      console.log("openKeys", val);
    },
    handleClick(e) {
      console.log("click", e);
    },
    titleClick(e) {
      console.log("titleClick", e);
    },
    async ChangePage(val) {
      // // console.log('เข้า ChangePage', val)
      // // console.log(val, this.$router.currentRoute)
      if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    CheckHeader(val) {
      this.Header = val;
    },
    Logout() {
      localStorage.removeItem("userBebraveWeb");
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.centerdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.div_overflow {
  background-color: #f2f4f7;
  overflow: auto;
  width: 100%;
  height: 94vh;
}
</style>
<style>
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}
</style>
