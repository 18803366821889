<template>
  <v-app style="background-color: #f2f4f7">
    <router-view />
  </v-app>
</template>


<script>
export default {
  methods: {},
};
</script>
<style>
@import "./assets/styles.css";
</style>
